<template>
  <div class="reset-passwrod">
    <LoginHeader />
    <div class="page-wrap">
      <div class="page-content-wrap">
        <div class="reset-form">
          <div class="title">设置新密码</div>
          <div class="form-content">
            <div class="form-item">
              <div class="label"><span>*</span>手机号码：</div>
              <div class="form-data-box">
                <input v-model="params.mobile" type="text" placeholder="请输入手机号码">
              </div>
            </div>
            <div class="form-item">
              <div class="label"><span>*</span>验证码：</div>
              <div class="form-data-box">
                <input v-model="params.code" type="text" placeholder="请输入验证码">
                <div @click="getCodeHandle" class="get-code-btn">{{codeText}}</div>
              </div>
            </div>
            <div class="form-item">
              <div class="label"><span>*</span>新密码：</div>
              <div class="form-data-box">
                <input v-model="params.password" type="password" placeholder="请输入6-20位的密码">
              </div>
            </div>
            <div class="form-item">
              <div class="label"><span>*</span>确认密码：</div>
              <div class="form-data-box">
                <input v-model="params.password_confirmation" type="password" placeholder="请再次输入密码">
              </div>
            </div>
          </div>
          <div @click="resttHandle" class="reset-btn">确认重置</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoginHeader from '@/components/header/header-login.vue'
import md5 from 'js-md5'
export default {
  components: {
    LoginHeader
  },
  data () {
    return {
      params: {
        mobile: '',
        code: '',
        password: '',
        password_confirmation: ''
      },
      key: process.env.VUE_APP_SIGNKEY,
      codeText: '获取验证码',
      timer: null,
      num: 60
    }
  },
  methods: {
    // 重置
    async resttHandle () {
      if (!this.checktel(this.params.mobile)) {
        return false
      }
      if (!this.params.code) {
        this.$message({
          type: 'error',
          message: '验证码不能为空',
          duration: 1500
        })
        return false
      }
      if (!this.params.password) {
        this.$message({
          type: 'error',
          message: '密码不能为空',
          duration: 1500
        })
        return false
      }
       if (!this.params.password_confirmation) {
        this.$message({
          type: 'error',
          message: '请再次输入密码',
          duration: 1500
        })
        return false
      }
      if (this.params.password !== this.params.password_confirmation) {
        this.$message({
          type: 'error',
          message: '两次输入的密码不一致',
          duration: 1500
        })
        return false
      }
      console.log(this.params)
      await this.$http.post('/api/forget-password', this.params)
      this.$message({
        type: 'success',
        message: '密码重置成功，请重新登录',
        duration: 2000
      })
      this.$router.push({ path: '/login' })
    },
    // 获取验证码
    async getCodeHandle() {
      if (this.num != 60) {
        return false
      }
      if (!this.checktel(this.params.mobile)) {
        return false
      }
      const timestamp = parseInt(new Date().getTime() / 1000).toString()
      const params = {
        mobile: this.params.mobile,
        sign: md5(md5(timestamp) + md5(this.params.mobile) + md5(this.key)),
        timestamp: Number(timestamp)
      }
      const _this = this
      this.timer = setInterval(() => {
        _this.num = _this.num - 1
        if (_this.num <= 0) {
          clearInterval(_this.timer)
          _this.codeText = '获取验证码'
          _this.num = 60
        } else {
          _this.codeText = _this.num  + 's后重试'

        }
      }, 1000)
      console.log(params)
      await this.$http.post('/common/sendsms', params)
    },
    // 手机号校验
    checktel (val){
      if (val=='') {
        this.$message.error('手机号不能为空')
        return false
      }
      let myreg = /^[1][2,3,4,5,7,6,8,9][0-9]{9}$/
      if (!myreg.test(val)) {
        this.$message.error('手机号格式不正确')
        return false
      }
      return true
    }
  }
}
</script>
<style lang="scss" scoped>
.reset-passwrod {
  .page-wrap {
    background-image: url('../../assets/images/common/regist_bg.png');
    background-size: cover;
    background-position: center center;
    .page-content-wrap {
      padding: 50px 0;
      .reset-form {
        background-color: #ffffff;
        margin: 0 auto;
        width: 500px;
        border-radius: 5px;
        padding: 40px 30px;
        font-size: 16px;
        .title {
          text-align: center;
          color: #D9262C;
          font-size: 24px;
          line-height: 1.1;
          margin-bottom: 10px;
        }
        .form-content {
          margin-top: 20px;
        }
        .form-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .label {
            text-align: right;
            min-width: 87px;
            span {
              color: red;
            }
          }
          .form-data-box {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #e6e6e6;
            flex: 1;
            input {
              flex: 1;
              border: 0 none;
              outline: none;
              line-height: 60px;
              font-size: 16px;
            }
            .get-code-btn {
              color: #D9262C;
              cursor: pointer;
            }
          }
        }
        .reset-btn {
          width: 100%;
          height: 45px;
          background-color: #D9262C;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          font-size: 16px;
          cursor: pointer;
          margin-top: 40px;
        }
      }
    }
  }
}
</style>
